import ReactApexChart from 'react-apexcharts'
import {useSelector, useDispatch} from 'react-redux'
import {getTotalExamParticipation} from '../../../data/StudyPeriod/studyPeriodSlice'
import {Spinner} from 'react-bootstrap'
import {useQuery} from 'react-query'

const TotalExamParticipation = () => {
  const {TotalExamParticipation} = useSelector((state: any) => state.studyPeriod)
  const dispatch = useDispatch()

  const {isLoading} = useQuery({
    queryKey: ['TotalExamParticipation'],
    queryFn: () => dispatch(getTotalExamParticipation(TotalExamParticipation) as any),
  })

  return (
    <div>
      <div className={`card`}>
        {/* begin::Body */}
        <div className='card-body'>
          {isLoading ? (
            <Spinner animation='border' variant='secondary' />
          ) : (
            <ReactApexChart
              options={{
                ...TotalExamParticipation.options,
                xaxis: {
                  categories: [...TotalExamParticipation.options.xaxis.categories],
                },
              }}
              series={TotalExamParticipation.series}
              type='area'
              height={400}
            />
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default TotalExamParticipation
