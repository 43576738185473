
import { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import ReactApexChart from 'react-apexcharts';
import { useSelector, useDispatch } from 'react-redux';
import { getCourseExamParticipation } from '../../../data/StudyPeriod/studyPeriodSlice';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { CardStudyPeriod } from '../../cards/cardZad1';
import PaginationZad from '../../helper/PaginationZad';
import { Spinner } from 'react-bootstrap';

import {formatNumber} from '../../../utils/axios';

const CourseExamParticipations = () => {
  const chartRef = useRef(null);

  const {courseExamParticipation, averageGrade, averageParticipation} = useSelector(
    (state: any) => state.studyPeriod
  )
  const dispatch = useDispatch()
  
  const courses = ['AQ', 'AR', 'FQ', 'HD', 'SR', 'TB', 'TS']
  const semesters = [1, 2, 3, 4]
  const categories = [...courseExamParticipation.options.xaxis.categories]
  
  const [selectedCourse, setSelectedCourse] = useState(courses[0])
  const [selectedSemester, setSelectedSemester] = useState(semesters[0])

  // Name of course in Buttons
  const courseButtons = [
    {name: 'Aqeedah', value: 'AQ'},
    {name: 'Tafsir', value: 'TS'},
    {name: 'Hadith', value: 'HD'},
    {name: 'Seerah', value: 'SR'},
    {name: 'Fiqh', value: 'FQ'},
    {name: 'Tarbiyah', value: 'TB'},
    {name: 'Arabic', value: 'AR'},
  ]

  const { } = useQuery({
    queryKey: ['courseExamParticipation', selectedCourse, selectedSemester],
    queryFn: () =>
      dispatch(
        getCourseExamParticipation({ course: selectedCourse, semester: selectedSemester }) as any
      ),
    enabled: !!(selectedCourse && selectedSemester),
    onSuccess: () => {
      const chartInstance = chartRef.current as any;
  
      if (chartInstance) {
        chartInstance.chart.hideSeries('Exam Participations');
      }
    },
  });

  // handle cousrse
  const handleCourseChange = (course) => {
    setSelectedCourse(course)
  }

  // handle Semester
  const handleSemesterChange = (semester) => {
    setSelectedSemester(semester)
  }

  return (
    <div>
      <div className="row">
        
        <div className="col-12 col-lg-9 order-2 order-lg-1">
      <div className={`card student-container`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='card-title align-items-start flex-column'>
            {/* Semester Buttons */}
            <PaginationZad
              titlePagination='Semesters :'
              items={semesters}
              activeItem={selectedSemester}
              onChange={handleSemesterChange}
            />
          </div>

          {/* begin::Toolbar */}
          <div className='card-toolbar' data-kt-buttons='true'>
            {/* Course Buttons */}
            <ButtonGroup className='zad-toggle-btn'>
              {courseButtons.map((courses, idx) => (
                <ToggleButton
                  key={idx}
                  id={`courses-${idx}`}
                  type='radio'
                  name='courses'
                  value={courses.value}
                  checked={selectedCourse.toString() === courses.value}
                  onChange={() => handleCourseChange(courses.value)}
                  active={selectedCourse.toString() === courses.value}
                  // className="cat-color"
                  className={`cat-color-${courses.value}`}
                >
                  {courses.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
          {/* end::Toolbar */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body'>
          
            
            {/* {isLoading ? (
              <Spinner animation='border' variant='secondary' />
            ) : ( */}
              <ReactApexChart
              ref={chartRef}
                options={{
                  ...courseExamParticipation.options,
                  xaxis: {
                    categories: [...categories],
                  },
                }}
                series={courseExamParticipation.series}
                type='line'
                height={400}
              />
              {/* )}
           */}
        </div>

        </div>
        </div>
        <div className="col-12 col-lg-3 order-1 order-lg-2 mb-5">
          <div className='cover-cards h-100 d-flex flex-lg-column justify-content-between '>
                <CardStudyPeriod
                  svgIcon='chart-pie-4'
                  iconColor='muted'
                  title=''
                  description={formatNumber(averageGrade) }
                  statusDesc='Average Grade'
                  className='w-75 me-2 me-lg-0'
                />

                <CardStudyPeriod
                  svgIcon='chart-pie-too'
                  iconColor='muted'
                  title=''
                  description={formatNumber(averageParticipation) }
                  statusDesc='Average Participation'
                  className='w-75'
                />
              </div>
        </div>
        {/* end::Body */}
      </div>
      

    </div>
    
  )
}

export default CourseExamParticipations

// import { useState, useRef, useEffect } from 'react';
// import { useQuery } from 'react-query';
// import ReactApexChart from 'react-apexcharts';
// import { useSelector, useDispatch } from 'react-redux';
// import { getCourseExamParticipation } from '../../../data/StudyPeriod/studyPeriodSlice';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import ToggleButton from 'react-bootstrap/ToggleButton';
// import { CardStudyPeriod } from '../../cards/cardZad1';
// import PaginationZad from '../../helper/PaginationZad';
// import { Spinner } from 'react-bootstrap';

// const formatNumber = (number) => {
//   var roundedNumber = Math.round(number * 100) / 100;
//   return roundedNumber.toLocaleString('de-DE', {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   });
// };

// const CourseExamParticipations = () => {
//   const chartRef = useRef(null);

//   const { courseExamParticipation, averageGrade, averageParticipation } = useSelector(
//     (state: any) => state.studyPeriod
//   );
//   const dispatch = useDispatch();

//   const courses = ['AQ', 'AR', 'FQ', 'HD', 'SR', 'TB', 'TS'];
//   const semesters = [1, 2, 3, 4];
//   const categories = [...courseExamParticipation.options.xaxis.categories];

//   const [selectedCourse, setSelectedCourse] = useState(courses[0]);
//   const [selectedSemester, setSelectedSemester] = useState(semesters[0]);

//   // Name of course in Buttons
//   const courseButtons = [
//     { name: 'Aqeedah', value: 'AQ' },
//     { name: 'Tafsir', value: 'TS' },
//     { name: 'Hadith', value: 'HD' },
//     { name: 'Seerah', value: 'SR' },
//     { name: 'Fiqh', value: 'FQ' },
//     { name: 'Tarbiyah', value: 'TB' },
//     { name: 'Arabic', value: 'AR' },
//   ];

//   const { } = useQuery({
//     queryKey: ['courseExamParticipation', selectedCourse, selectedSemester],
//     queryFn: () =>
//       dispatch(
//         getCourseExamParticipation({ course: selectedCourse, semester: selectedSemester }) as any
//       ),
//     enabled: !!(selectedCourse && selectedSemester),
//   });

//   // handle cousrse
//   const handleCourseChange = (course) => {
//     setSelectedCourse(course);
//   };

//   // handle Semester
//   const handleSemesterChange = (semester) => {
//     setSelectedSemester(semester);
//   };

//   // useEffect(() => {
//   //   const chartInstance: any = (chartRef.current as any)?.chartInstance;
  
//   //   if (chartInstance) {
//   //     // Check if 'Exam Participations' series is hidden, then show it
//   //     const seriesIndex = chartInstance.w.globals.seriesNames.indexOf('Exam Participations');
  
//   //     if (seriesIndex !== -1 && !chartInstance.w.globals.series[seriesIndex].visible) {
//   //       chartInstance.toggleSeries('Exam Participations');
//   //     }
//   //   }
//   // }, [chartRef, selectedCourse, selectedSemester]);

//   useEffect(() => {
//     const chartInstance: any = (chartRef.current as any)?.chartInstance;
  
//     if (chartInstance) {
//       // Check if 'Exam Participations' series is hidden, then show it
//       const seriesIndex = chartInstance.w.globals.seriesNames.indexOf('Exam Participations');
  
//       if (seriesIndex !== -1 && chartInstance.w.globals.series[seriesIndex].visible === false) {
//         chartInstance.toggleSeries('Exam Participations');
//       }
//     }
//   }, [chartRef, selectedCourse, selectedSemester]);
  

//   return (
//     <div>
//       <div className="row">
//         <div className="col-9">
//           <div className={`card student-container`}>
//             {/* begin::Header */}
//             <div className='card-header border-0 pt-5'>
//               <div className='card-title align-items-start flex-column'>
//                 {/* Semester Buttons */}
//                 <PaginationZad
//                   titlePagination='Semesters :'
//                   items={semesters}
//                   activeItem={selectedSemester}
//                   onChange={handleSemesterChange}
//                 />
//               </div>

//               {/* begin::Toolbar */}
//               <div className='card-toolbar' data-kt-buttons='true'>
//                 {/* Course Buttons */}
//                 <ButtonGroup className='zad-toggle-btn'>
//                   {courseButtons.map((courses, idx) => (
//                     <ToggleButton
//                       key={idx}
//                       id={`courses-${idx}`}
//                       type='radio'
//                       name='courses'
//                       value={courses.value}
//                       checked={selectedCourse.toString() === courses.value}
//                       onChange={() => handleCourseChange(courses.value)}
//                       active={selectedCourse.toString() === courses.value}
//                       className={`cat-color-${courses.value}`}
//                     >
//                       {courses.name}
//                     </ToggleButton>
//                   ))}
//                 </ButtonGroup>
//               </div>
//               {/* end::Toolbar */}
//             </div>
//             {/* end::Header */}

//             {/* begin::Body */}
//             <div className='card-body'>
//               <ReactApexChart
//                 ref={chartRef}
//                 options={{
//                   ...courseExamParticipation.options,
//                   xaxis: {
//                     categories: [...categories],
//                   },
//                 }}
//                 series={courseExamParticipation.series}
//                 type='line'
//                 height={400}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="col-3">
//           <div className='cover-cards h-100 d-flex flex-column justify-content-between'>
//             <CardStudyPeriod
//               svgIcon='chart-pie-4'
//               iconColor='muted'
//               title=''
//               description={formatNumber(averageGrade)}
//               statusDesc='Average Grade'
//             />

//             <CardStudyPeriod
//               svgIcon='chart-pie-too'
//               iconColor='muted'
//               title=''
//               description={formatNumber(averageParticipation)}
//               statusDesc='Average Participation'
//             />
//           </div>
//         </div>
//         {/* end::Body */}
//       </div>
//     </div>
//   );
// };

// export default CourseExamParticipations;

