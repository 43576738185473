
import {useDispatch, useSelector} from 'react-redux'
import ReactApexChart from 'react-apexcharts'
import {getPassedFailedStu} from '../../../data/StudyPeriod/studyPeriodSlice'
import {Spinner} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import { useQuery } from 'react-query'
import {CardStudyPeriod} from '../../cards/cardZad1'

const formatNumber = (number) => {
  if (number >= 1000) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else if (number <= 100) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number.toString();
  }
}

const PassedFailedStu = () => {
  const { totalStudentLogin ,attemptsCount} = useSelector(
    (state: any) => state.studyPeriod
  )
  const { PassedFailedStu} = useSelector((state: any) => state.studyPeriod)
  const dispatch = useDispatch()
  
  const {isLoading} = useQuery({
    queryKey: ['passedFailedStudent'],
    queryFn: () => dispatch(getPassedFailedStu(PassedFailedStu) as any)
  })

  return (
    <div>
      <div className={`passCard card`}>
        {/* begin::Header */}
        <CardStudyPeriod
              svgIcon='message-text'
              iconColor='muted'
              title=''
              description={formatNumber (attemptsCount)}
              statusDesc='Total Exam Participation'
              className=''
            />
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body'>
          {isLoading ? (
            <Spinner animation='border' variant='secondary' />
          ) : (
            <ReactApexChart
              options={PassedFailedStu.options}
              series={PassedFailedStu.series}
              type='donut'
              height={300}
            />
          )} 
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default PassedFailedStu
