import {FC, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  getNumTotalExPar,
  getTotalActiveStudent,
  getCountriesCount,
} from '../../data/StudyPeriod/studyPeriodSlice'
import {CardStudyPeriod} from '../../components/cards/cardZad1'

import StudyPeriodDate from '../../components/helper/StudyPeriodDate'
import {PageTitle} from '../../../_metronic/layout/core'
import ActiveStudentContainer from '../../components/charts/StudyPeriod/ActiveStudentContainer'
import TotalExamParticipations from '../../components/charts/StudyPeriod/TotalExamParticipations'
import CourseExamParticipations from '../../components/charts/StudyPeriod/CourseExamParticipations'
import PassedFailedStu from '../../components/charts/StudyPeriod/PassedFailedStu'

import FilterContainer from '../../components/helper/FilterContainer'
import LanguageFilter from '../../components/helper/LanguageFilter'
import {useQuery} from 'react-query'

// KTIcon} from '../../../../../../../_metronic/helpers'

import {KTIcon} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'

import {formatNumber} from '../../utils/axios'

const StudyPeriod: FC = () => {
  const {attemptsCount, totalActiveStudent, totalStudentLogin, totalAverageGrade, countryNum} =
    useSelector((state: any) => state.studyPeriod)
  const dispatch = useDispatch()

  useQuery({
    queryKey: ['numberattemptAndtotalActiveStudent'],
    queryFn: async () => {
      const attemptsCountResult = await dispatch(getNumTotalExPar(attemptsCount) as any)
      const countryNums = await dispatch(getCountriesCount(countryNum) as any)
      const totalActiveStudentResult = await dispatch(
        getTotalActiveStudent(totalActiveStudent) as any
      )
      return {attemptsCountResult, totalActiveStudentResult, countryNums}
    },
  })

  return (
    <>
      <div className='innerpage-header'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            {/* <PageTitle >Study Periods</PageTitle> */}
            <StudyPeriodDate />
          </div>
          <div className='col-12 col-lg-3 offset-lg-3 text-end mt-4'>
            <button
              // disabled={isLoading}
              type='button'
              className='btn  me-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='filter' className='fs-2' />
            </button>

            {/* <Dropdown1 /> */}
            <LanguageFilter />
            {/* <FilterContainer /> */}
            {/* <span className="ki-filter-search ki-outline fa-2x pe-3 "></span> */}

            {/* <div className="d-flex align-items-center gap-2 gap-lg-3">
										
										<div className="m-0">
											
											<a href="#" className="btn btn-sm btn-flex btn-secondary fw-bold" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
											<i className="ki-duotone ki-filter fs-6 text-muted me-1">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>Filter</a>
											
									
											<div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b7761f3b9d5">
												
												<div className="px-7 py-5">
													<div className="fs-5 text-dark fw-bold">Filter Options</div>
												</div>
												<div className="separator border-gray-200"></div>
												
												
												<div className="px-7 py-5">
													<div className="mb-10">
														<label className="form-label fw-semibold">Status:</label>
														<div>
															<select className="form-select form-select-solid"  data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b7761f3b9d5" data-allow-clear="true">
																<option></option>
																<option value="1">Approved</option>
																<option value="2">Pending</option>
																<option value="2">In Process</option>
																<option value="2">Rejected</option>
															</select>
														</div>
													</div>
													<div className="mb-10">
														<label className="form-label fw-semibold">Member Type:</label>
														<div className="d-flex">
															<label className="form-check form-check-sm form-check-custom form-check-solid me-5">
																<input className="form-check-input" type="checkbox" value="1" />
																<span className="form-check-label">Author</span>
															</label>
															<label className="form-check form-check-sm form-check-custom form-check-solid">
																<input className="form-check-input" type="checkbox" value="2" checked />
																<span className="form-check-label">Customer</span>
															</label>
														</div>
													</div>
													<div className="mb-10">
														<label className="form-label fw-semibold">Notifications:</label>
														<div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
															<input className="form-check-input" type="checkbox" value="" name="notifications" checked />
															<label className="form-check-label">Enabled</label>
														</div>
													</div>
													<div className="d-flex justify-content-end">
														<button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
														<button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
													</div>
												</div>
											</div>
										</div>
									</div>
             */}
            <button className='btn btn-zad' type='button' data-kt-element='send'>
              Export Report
            </button>
          </div>
        </div>
      </div>

      {/* .number- */}
      <div className='row'>
        <h5 className='card-title-zad mb-0'>Overview</h5>
        <div className='col'>
          <CardStudyPeriod
            // <i className="fa-solid fa-circle-user"></i>
            svgIcon='profile-circle'
            iconColor='muted'
            title=''
            description={formatNumber(totalStudentLogin)}
            statusDesc='Engaged Students'
            className="card-zad-sm"
          />
        </div>
        <div className='col'>
          <CardStudyPeriod
            svgIcon='notification-status'
            // svgIcon='chart-simple'
            iconColor='muted'
            title=''
            description={formatNumber(totalActiveStudent)}
            statusDesc='Active Students'
            className="card-zad-sm"
          />
        </div>
        <div className='col'>
          <CardStudyPeriod
            svgIcon='geolocation-home'
            iconColor='muted'
            title=''
            description={countryNum}
            statusDesc='Countries'
            className="card-zad-sm"
          />
        </div>
        <div className='col'>
          <CardStudyPeriod
            svgIcon='message-text'
            iconColor='muted'
            title=''
            description={formatNumber(attemptsCount)}
            statusDesc='Total Exam Participation'
            className="card-zad-sm"
          />
        </div>
        <div className='col'>
          <CardStudyPeriod
            svgIcon='mouse-circle'
            iconColor='muted'
            title=''
            description={totalAverageGrade.toString().replace('.', ',')}
            statusDesc='Average Grades'
            className="card-zad-sm"
          />
        </div>
      </div>
      {/* Active Student Container */}
      <div className='row g-5 g-xl-12 mt-5'>
        {/* <h5 className='card-title-zad mb-0'>Active Student</h5> */}
        <div className='col-xl-12'>
          <ActiveStudentContainer />
        </div>
      </div>

      {/* Total Exam Participation   */}
      <h5 className='card-title-zad  mb-0'>Exam Participation</h5>
      <div className='row g-5 g-xl-12'>
        <div className='col-xl-3'>
          <div className='cover-cards h-100 d-flex flex-column justify-content-between'>
            {/* <CardStudyPeriod
              svgIcon='mouse-circle'
              iconColor='muted'
              title=''
              description={totalStudentLogin}
              statusDesc='Pass Rate'
            /> */}
            <PassedFailedStu />
          </div>
        </div>
        <div className='col-xl-9'>
          <TotalExamParticipations />
        </div>
      </div>

      {/* curriculum Summary   */}
      <h5 className='card-title-zad  mb-0'>Course Details</h5>
      <div className='row  g-xl-12 mt-5'>
        <div className='col-xl-12'>
          <CourseExamParticipations />
        </div>
      </div>
    </>
  )
}

export {StudyPeriod}
