import {Form, Pagination} from 'react-bootstrap'

const PaginationZad = ({
  titlePagination,
  items,
  activeItem,
  onChange,
}) => {
  return (
    <Form>
      <Form.Group controlId='customPagination'>
        <Form.Label>{titlePagination}</Form.Label>
        <Pagination>
          {items.map((item, idx) => (
            <Pagination.Item
              key={idx}
              active={activeItem === item}
              onClick={() => onChange(item)}
              className='zad-pag'
            >
              {item}
            </Pagination.Item>
          ))}
        </Pagination>
      </Form.Group>
    </Form>
  )
}

export default PaginationZad
