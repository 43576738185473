/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import { toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'


const SidebarFooter = () => {
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      {/* <a
        href={process.env.REACT_APP_PREVIEW_DOCS_URL}
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Metronic Docs & Components'
      >
        <span className='btn-label'>Docs & Components</span>
        <KTIcon iconName='document' className='btn-icon fs-2 m-0' />
      </a> */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/user.png')} alt='' />
          {/* <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} alt='' /> */}
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {SidebarFooter}
