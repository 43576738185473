import axios from "axios";
import { setupAxios } from "../modules/auth";

const API_URL = 'https://dw-api.zad.academy'

const axiosInstance = axios.create({
  baseURL: API_URL,
});

setupAxios(axiosInstance);

export default axiosInstance;


// Format Number Function
export const formatNumber = (number) => {
  var roundedNumber = Math.round(number * 100) / 100;

  // Format the number with minimum and maximum fraction digits
  let formattedNumber = roundedNumber.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  formattedNumber = formattedNumber.replace(/\.?0*$/, '');

  formattedNumber = formattedNumber.replace(/,$/, '');

  return formattedNumber;
};