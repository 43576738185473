import {useQuery} from 'react-query'
import ReactApexChart from 'react-apexcharts';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveStudent } from '../../../data/StudyPeriod/studyPeriodSlice';
import {Spinner} from 'react-bootstrap'



const ActiveStudent = () => {
  const { activeStudentData } = useSelector((state: any) => state.studyPeriod);
  const dispatch = useDispatch();
  const categories = [...activeStudentData.options.xaxis.categories];
  
  const {isLoading} = useQuery({
    queryKey: ['activeStudent'],
    queryFn: () => dispatch(getActiveStudent(activeStudentData) as any),
  });

  return (
    <div>
      {isLoading ? (
        <Spinner animation='border' variant='secondary' />
      ) : (
        <div>
          <ReactApexChart
            options={{
              ...activeStudentData.options,
              xaxis: {
                categories: [...categories],
              },
            }}
            series={activeStudentData.series}
            type='bar'
            height={350}
          />
        </div>
      )}
    </div>
  );
};

export default ActiveStudent;