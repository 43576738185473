import React, {useEffect, useState} from 'react'
import FormSelect from './FormSelect'
import {useSelector, useDispatch} from 'react-redux'
import {handleChangeLang, getTotalExamParticipation, getActiveStudent, getTotalActiveStudent,getPassedFailedStu, getNumTotalExPar, getCourseExamParticipation,getCountriesCount} from '../../data/StudyPeriod/studyPeriodSlice'
import {useAuth} from '../../modules/auth/core/Auth'
import { toHaveAccessibleDescription } from '@testing-library/jest-dom/matchers'

const FilterContainer = () => {
  const {searchLang, languageFilter} = useSelector((state: any) => state.studyPeriod)
  const dispatch = useDispatch()
  const {currentUser} = useAuth() 
  const [selectedLang, setSelectedLang] = useState(currentUser?.default_program) 

  const courses = ['AQ', 'AR', 'FQ', 'HD', 'SR', 'TB', 'TS']
  const semesters = [1, 2, 3, 4]
  const [selectedCourse, setSelectedCourse] = useState(courses[0])
  const [selectedSemester, setSelectedSemester] = useState(semesters[0])

  const handleSearch = (lang: string) => {
    dispatch(handleChangeLang({name: 'searchLang', value: lang}))
    dispatch(getActiveStudent({searchLang: lang}) as any)
    dispatch(getTotalActiveStudent({searchLang: lang}) as any)
    dispatch(getTotalExamParticipation({searchLang: lang}) as any)
    dispatch(getPassedFailedStu({searchLang: lang}) as any)
    dispatch(getNumTotalExPar({searchLang: lang}) as any)
    dispatch(getCountriesCount({searchLang: lang}) as any)
    dispatch(getCourseExamParticipation({ course: selectedCourse, semester: selectedSemester }) as any);

  }

  useEffect(()=>{
    handleSearch(currentUser?.default_program || 'ar');
  }, []) 


  return (
    <>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='mb-5'>
            <label className='form-label fw-bold filter-label'>Data of Reports:</label>

            <div className='d-flex '>
              <div className='form-check pe-4'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='lang'
                  id='ar'
                  value='ar'
                  checked={selectedLang === 'ar'}
                  onChange={() => {
                    setSelectedLang('ar')
                    handleSearch('ar')
                  }}
                />
                <label className='form-check-label' htmlFor='ar'>
                  Arabic
                </label>
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='lang'
                  id='en'
                  value='en'
                  checked={selectedLang === 'en'}
                  onChange={() => {
                    setSelectedLang('en')
                    handleSearch('en')
                  }}
                />
                <label className='form-check-label' htmlFor='en'>
                  English
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterContainer
