/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
type Props = {
  title: string
  description: string
  statusDesc: string
  svgIcon: string
  iconColor: string
  className: string
}

const CardStudyPeriod: FC<Props> = ({title, description, statusDesc, svgIcon, iconColor, className}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header flex-nowrap border-0 pt-9'>
        <div className='card-title m-0'>
          {/* <div className='symbol symbol-45px w-40px me-5'> */}
          <div className='symbol-label bg-lighten me-3'>
            <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />
          </div>
          {/* </div> */}
          <h4 className='fs-4 fw-bold text-gray-600'>{title}</h4>
        </div>
      </div>
      <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
        <div className='fs-2tx fw-bolder mb-1'>{description}</div>
        <div className='fw-bold text-gray-400'>{statusDesc}</div>
      </div>
    </div>
  )
}

export {CardStudyPeriod}
