import axiosInstance from '../../utils/axios'

const API_URL = process.env.REACT_APP_API_URL

// get Study Period data
export const getStudyPeriodsThunk = async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/dashboard/study_period`)

    return response.data
  } catch (error) {
    return console.log(thunkAPI.rejectWithValue('Error fetching study periods'))
  }
}

export const getCountriesCountThunk = async (_, thunkAPI) => {
  const {searchLang} = thunkAPI.getState().studyPeriod
  const countFilter = {
    filters: [
      {
        type: 'language',
        value: [searchLang],
      },
    ],
  }
  try {
    const resp = await axiosInstance.post(`${API_URL}/report/countries-count`, countFilter)

    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error Active Student Rebort')
  }
}

// Active Student Chart
export const getActiveStudentThunk = async (_, thunkAPI) => {
  const {searchPeriod, searchLang} = thunkAPI.getState().studyPeriod
  const activeStudentFilter = {
    filters: [
      {
        type: 'language',
        value: [searchLang],
      },
      {
        type: 'study_period',
        value: searchPeriod,
      },
      {
        type: 'day_of_week',
        value: 'Monday',
      },
    ],
    group_by: ['exam_id'],
  }
  try {
    const resp = await axiosInstance.post(`${API_URL}/report/history/`, activeStudentFilter)
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error Active Student Rebort')
  }
}
// Active Total Student Chart
export const getTotalActThunk = async (_, thunkAPI) => {
  const {searchPeriod, searchLang} = thunkAPI.getState().studyPeriod
  const activeStudentFilter = {
    filters: [
      {
        type: 'language',
        value: [searchLang],
      },
      {
        type: 'study_period',
        value: searchPeriod,
      },
      {
        type: 'day_of_week',
        value: 'Monday',
      },
    ],
    group_by: ['exam_id'],
  }
  try {
    const resp = await axiosInstance.post(`${API_URL}/report/history/`, activeStudentFilter)
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error Active Student Rebort')
  }
}

// Active Student Increase Chart
export const getActiveStudentIncThunk = async (_, thunkAPI) => {
  const {searchPeriod} = thunkAPI.getState().studyPeriod
  const activeStudentFilter = {
    filters: [
      {
        type: 'study_period',
        value: searchPeriod,
      },
      {
        type: 'day_of_week',
        value: 'Monday',
      },
    ],
    group_by: ['exam_id'],
  }
  try {
    const resp = await axiosInstance.post(`${API_URL}/report/history/`, activeStudentFilter)
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error Active Student Inc Rebort')
  }
}

export const getTotalExamParticipationThunk = async (language, thunkAPI) => {
  const {searchPeriod, searchLang} = thunkAPI.getState().studyPeriod
  const activeStudentFilter2 = {
    filters: [
      {
        type: 'language',
        value: [searchLang],
      },
      {
        type: 'study_period',
        value: searchPeriod,
      },
    ],
    group_by: ['exam_id'],
  }

  try {
    const resp = await axiosInstance.post(`${API_URL}/report/quiz-attempts`, activeStudentFilter2)
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error TotalExamParticipation Report')
  }
}

// Passed Failed Exam
export const getPassedFailedStuThunk = async (_, thunkAPI) => {
  const {searchPeriod, searchLang} = thunkAPI.getState().studyPeriod
  const activeStudentFilter2 = {
    filters: [
      {
        type: 'language',
        value: [searchLang],
      },

      {
        type: 'study_period',
        value: searchPeriod,
      },
    ],
    group_by: ['study_period'],
  }

  try {
    const resp = await axiosInstance.post(`${API_URL}/report/quiz-attempts`, activeStudentFilter2)
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error TotalExamParticipation Rebort')
  }
}

// Total Exam participation Number
export const getNumTotalExParThunk = async (_, thunkAPI) => {
  const {searchPeriod, searchLang} = thunkAPI.getState().studyPeriod
  const activeStudentFilter3 = {
    filters: [
      {
        type: 'language',
        value: [searchLang],
      },

      {
        type: 'study_period',
        value: searchPeriod,
      },
    ],
    group_by: ['study_period'],
  }

  try {
    const resp = await axiosInstance.post(`${API_URL}/report/quiz-attempts`, activeStudentFilter3)
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error TotalExamParticipation Rebort')
  }
}

// Course Exam Participation
export const getCourseExamParticipationThunk = async ({course, semester}, thunkAPI) => {
  const {searchPeriod, searchLang} = thunkAPI.getState().studyPeriod
  const activeStudentFilter2 = {
    filters: [
      {
        type: 'language',
        value: [searchLang],
      },
      {
        type: 'study_period',
        value: searchPeriod,
      },
      {
        type: 'course',
        value: [course],
      },
      {
        type: 'semester',
        value: [semester],
      },
    ],
    group_by: ['exam_id'],
  }

  try {
    const resp = await axiosInstance.post(`${API_URL}/report/quiz-attempts`, activeStudentFilter2)
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue('There was an error TotalExamParticipation Rebort')
  }
}
