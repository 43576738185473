import  { useState } from 'react';
import ToggleButton from '../../helper/toggleButton';
import ActiveStudent from './ActiveStudent';
import ActiveStudentInc from './ActiveStudentInc';

const ActiveStudentContainer = () => {
  // const [activeComponent, setActiveComponent] = useState('ActiveStudent');

  // const handleToggle = (component) => {
  //   setActiveComponent(component);
  // };

  return (
    <div className={`card student-container`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>Login and Active Students</h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* <ToggleButton
            label="Active Students"
            value="ActiveStudent"
            activeComponent={activeComponent}
            onClick={handleToggle}
          /> */}

          {/* <ToggleButton
            label="Active Students Increase"
            value="ActiveStudentInc"
            activeComponent={activeComponent}
            onClick={handleToggle}
          /> */}
        </div>
      </div>

      <div className='card-body'>
        <ActiveStudent />
        {/* {activeComponent === 'ActiveStudent' && <ActiveStudent />} */}
        {/* {activeComponent === 'ActiveStudentInc' && <ActiveStudentInc />} */}
      </div>
    </div>
  );
};

export default ActiveStudentContainer;
